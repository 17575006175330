import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'astroturf';
import { isArray, isObject } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { SimpleDropdown } from '../SimpleDropdown';
import { SelectMenu } from '../SelectMenu';
import { SelectMenuWrapper } from '../SelectMenuWrapper';
import { Checkbox } from '../Checkbox';
import { Ripple } from '../../../service/Ripple';
import { TextEllipsisWithTooltip } from '../../../service/TextEllipsisWithTooltip';
import { usePromiseCallback } from '../../../../service/hooks/usePromiseCallback';
import { Dict } from '../../../../service/typings/basic';
import { ReactComponent as EditIcon } from '../../../../images/icons/grid/edit-column.svg';
import { ReactComponent as RemoveIcon } from '../../../../images/icons/grid/remove-reference.svg';
import { AddSection } from './AddSection';
import { SearchSection } from './SearchSection';
import { EditSection } from './EditSection';
import { IconWrapper } from './IconWrapper';

type Item = string | number | Dict;

export interface AdvancedSelectProps {
	value?: any;
	placeholder?: string;
	searchable?: boolean;
	disabled?: boolean;
	items?: Item[];
	onGetItems?: () => any;
	onSearch?: (query: string) => Promise<any[]>;
	addable?: boolean;
	onAdd?: (value: string) => Promise<any>;
	onEdit?: (value: string, item: any) => Promise<any>;
	onRemove?: (item: any) => Promise<any>;
	onChange?: (item: any) => void;
	onBlur?: (e: React.FocusEvent<any>) => void;
	getKey?: (item: any) => string | number;
	getLabel?: (item: any) => string;
	addText?: string;
	editText?: string;
	searchText?: string;
	className?: string;
	disablePortal?: boolean;
	error?: string;
	touched?: boolean;
	multiple?: boolean;
	multiline?: boolean;
	sameWidth?: boolean;
	editable?: boolean;
	removable?: boolean;
	grow?: boolean;
	onClear?: () => void;
}

function getDefaultKey(item: Dict) {
	return item.id || item.key;
}

function getDefaultLabel(item: Dict) {
	return item.name || item.label;
}

export const AdvancedSelect: FC<AdvancedSelectProps> = ({
	className,
	placeholder,
	value,
	addable,
	onAdd,
	searchable,
	disabled,
	onSearch,
	onChange,
	items,
	onGetItems,
	addText,
	editText,
	getLabel,
	disablePortal,
	multiple,
	getKey,
	onBlur,
	touched,
	error,
	multiline,
	sameWidth,
	editable,
	removable,
	onEdit,
	onClear,
	onRemove,
	grow,
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [internalItems, setInternalItems] = useState<any[]>([]);
	const [searchQuery, setSearchQuery] = useState('');

	const [editingKey, setEditingKey] = useState<number | null>(null);

	const container = useRef<HTMLDivElement>(null);

	const preventOpen = useRef(false);

	const onAddItem = usePromiseCallback(
		(text: string) => {
			return onAdd?.(text).then((response) => {
				setInternalItems([response, ...internalItems]);
			});
		},
		[internalItems, setInternalItems, onAdd],
	);

	const onSearchItems = useCallback(
		(query: string) => {
			if (onSearch) {
				if (query.length) {
					onSearch(query.trim()).then((data: any[]) => {
						setInternalItems(data);
					});
				} else if (onGetItems) {
					onGetItems().then((data: any[]) => {
						setInternalItems(data);
					});
				}
			} else {
				setSearchQuery(query);
			}
		},
		[onSearch, onGetItems, setInternalItems],
	);

	const retrieveKey = useCallback(
		(item: Item, index?: number) => {
			if (isObject(item)) {
				return getKey ? getKey(item) : getDefaultKey(item);
			} else {
				return index;
			}
		},
		[getKey],
	);

	const retrieveLabel = useCallback(
		(item: Item) => {
			if (isObject(item)) {
				return getLabel ? getLabel(item) : getDefaultLabel(item);
			} else {
				return item;
			}
		},
		[getLabel],
	);

	const dropdownLabel = useMemo(() => {
		if (multiple) {
			if (isArray(value) && value.length) {
				return value.length > 1 ? `${t('common.ui.selected')}: ${value.length}` : retrieveLabel(value[0]);
			}
		} else if (value) {
			return retrieveLabel(value);
		}
	}, [t, multiple, retrieveLabel, value]);

	const onOpen = useCallback(() => {
		preventOpen.current = false;
		setSearchQuery('');
		if (!disabled) {
			if (items) {
				setInternalItems(items);
				setOpen(true);
			} else {
				if (onGetItems && !loading) {
					setLoading(true);
					Promise.resolve(onGetItems())
						.then((data) => {
							if (!preventOpen.current) {
								setInternalItems(data);
								setOpen(true);
							}
						})
						.finally(() => {
							setLoading(false);
						});
				}
			}
		}
	}, [setOpen, onGetItems, items, disabled, loading, setSearchQuery]);

	const onClose = useCallback(() => {
		setLoading(false);
		preventOpen.current = true;
		setOpen(false);
	}, [setOpen, setLoading]);

	const onSelect = useCallback(
		(item: any) => () => {
			if (onChange && !multiple) {
				onChange(item);
				setOpen(false);
			}
		},
		[onChange, multiple],
	);

	const onMultipleSelect = useCallback(
		(item: any, itemIndex: number) => (event: ChangeEvent<HTMLInputElement>) => {
			let items = value;
			if (!event.target.checked) {
				items = items.filter(
					(el: any, elIndex: number) => retrieveKey(el, elIndex) !== retrieveKey(item, itemIndex),
				);
			} else {
				items = items ? [...items, item] : [item];
			}

			if (onChange) {
				onChange(items);
			}
		},
		[onChange, value, retrieveKey],
	);

	const onClearInternal = () => {
		if (onClear) {
			onClear();
			onClose();
		}
	};

	const onDropdownClick = useCallback(() => {
		if (open) {
			onClose();
		} else {
			onOpen();
		}
	}, [onOpen, onClose, open]);

	const filteredItems = useMemo(() => {
		return searchQuery
			? internalItems.filter(
					(item) => retrieveLabel(item).toLowerCase().indexOf(searchQuery.toLowerCase().trim()) + 1,
			  )
			: internalItems;
	}, [searchQuery, internalItems, retrieveLabel]);

	const handleRemove = (item: Item) => {
		return onRemove?.(item).then(() => {
			setInternalItems((oldItems) => oldItems.filter((el) => retrieveKey(el) !== retrieveKey(item)));
			if (value) {
				if (isArray(value)) {
					onChange?.(value.filter((el) => retrieveKey(el) !== retrieveKey(item)));
				}
				if (isObject(value)) {
					if (retrieveKey(value) === retrieveKey(item)) {
						onChange?.(null);
					}
				}
			}
		});
	};

	const handleEdit = (label: string, item: Item) => {
		if (!label) {
			setEditingKey(null);
		} else {
			return onEdit?.(label, item).then((response) => {
				setInternalItems((oldItems) =>
					oldItems.map((element) => (retrieveKey(element) === editingKey ? response : element)),
				);
				setEditingKey(null);
				if (value) {
					if (isArray(value)) {
						onChange?.(value.map((el) => (retrieveKey(el) === editingKey ? response : el)));
					}
					if (isObject(value)) {
						if (retrieveKey(value) === editingKey) {
							onChange?.(response);
						}
					}
				}
			});
		}
	};

	useEffect(() => {
		setEditingKey(null);
	}, [open]);

	return (
		<Wrapper grow={grow}>
			<SimpleDropdown
				ref={container}
				className={className}
				value={dropdownLabel}
				disabled={disabled}
				open={open}
				placeholder={placeholder || t('common.ui.selectPlaceholder')}
				onClick={onDropdownClick}
				loading={loading}
				onBlur={onBlur}
				touched={touched}
				error={error}
				multiline={multiline}
				clearable={Boolean(value && onClear)}
				onClear={onClearInternal}
			/>
			<StyledSelectMenu
				onClickOutside={onClose}
				processClickOutside={open || loading}
				anchor={container}
				open={open}
				disablePortal={disablePortal}
				limitHeight
				sameWidth={sameWidth}
			>
				<StyledSelectMenuWrapper>
					{searchable && <SearchSection onSearch={onSearchItems} />}
					{addable && <AddSection onAdd={onAddItem} addText={addText} />}
					<Items>
						{filteredItems.map((item, index) => {
							const key = retrieveKey(item, index);

							return editable && editingKey === key ? (
								<EditSection
									onSubmit={(value: string) => handleEdit(value, item)}
									initialValue={retrieveLabel(item)}
									label={editText}
								/>
							) : (
								<ItemWrapper>
									<Ripple key={key} onClick={onSelect(item)}>
										<ItemBlock htmlFor={String(key)} withCheckbox={multiple}>
											{multiple && (
												<CheckboxWrapper>
													<Checkbox
														id={String(key)}
														onChange={onMultipleSelect(item, index)}
														checked={
															isArray(value) &&
															value.some((el: Item) => retrieveKey(el, index) === key)
														}
													/>
												</CheckboxWrapper>
											)}
											{multiline ? (
												<MultilineText>{retrieveLabel(item)}</MultilineText>
											) : (
												<InlineText>{retrieveLabel(item)}</InlineText>
											)}
											<Icons>
												{editable && (
													<IconWrapper onClick={() => setEditingKey(key)}>
														<EditIcon />
													</IconWrapper>
												)}
												{removable && (
													<IconWrapper onClick={() => handleRemove(item)}>
														<RemoveIcon />
													</IconWrapper>
												)}
											</Icons>
										</ItemBlock>
									</Ripple>
								</ItemWrapper>
							);
						})}
					</Items>
				</StyledSelectMenuWrapper>
			</StyledSelectMenu>
		</Wrapper>
	);
};

const StyledSelectMenu = styled(SelectMenu)`
	max-width: 576px;
	min-width: 192px;
`;

const StyledSelectMenuWrapper = styled(SelectMenuWrapper)`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const Icons = styled.div`
	display: flex;
	gap: 12px;
	margin-left: auto;
`;

const Wrapper = styled.div<{ grow?: boolean }>`
	@import '../../../../styles/constants.scss';
	position: relative;
	&.grow {
		min-width: 0;
		width: 100%;
	}
`;

const CheckboxWrapper = styled.div`
	margin-right: 7px;
`;

const ItemWrapper = styled.div`
	height: 36px;
`;

const ItemBlock = styled.label<{ withCheckbox?: boolean }>`
	@import '../../../../styles/constants.scss';
	text-align: left;
	font-size: 12px;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px 11px 10px;
	cursor: pointer;
	&:hover {
		background-color: rgba($color-gray, 0.5);
	}
	&.withCheckbox {
		padding: 2px 6px 2px;
	}
`;

const MultilineText = styled.span``;

const InlineText = styled(TextEllipsisWithTooltip)`
	padding-bottom: 1px;
	white-space: nowrap;
`;

const Items = styled.div`
	@import '../../../../styles/mixins.scss';
	@include thin-scrollbar(#d8d8d8);
	max-height: 320px;
	overflow: auto;
	width: 100%;
	height: 100%;
	margin-top: 4px;
	margin-bottom: 16px;
`;
const SaveButton = styled.div`
	margin: 6px 9px 6px 12px;
`;
